<template>
  <v-layout row wrap class="white web-mt-3">
    <v-progress-linear indeterminate v-if="loading" />
    <v-flex xs12>
      <v-list>
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title class="title">
              {{ apartment ? apartment.name : '' }} ({{ statusDescription }})
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-flex>
    <v-flex xs12 >
      <v-list class="auto-height">
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title>{{ $t('apartment_print.comments') }}</v-list-tile-title>
            <v-list-tile-sub-title v-if="apartment && apartment.handOverComments"><span style="white-space: pre-wrap">{{ apartment.handOverComments }}</span>
            </v-list-tile-sub-title>
            <v-list-tile-sub-title v-if="statusUserInfo" class="caption pt-2 font-italic"><span
              style="white-space: pre-wrap">{{ statusUserInfo }}</span></v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-flex>
    <v-flex v-if="reclamationRows && reclamationRows.length > 0" xs12>
      <v-list>
        <v-list-tile class="auto-height">
          <v-list-tile-content>
            <v-list-tile-title class="mb-2">
              {{ $t('apartment_print.reclamation_rows') }}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <template v-for="reclamationRow in reclamationRows">
          <print-reclamation-list-item :key="reclamationRow.key" :reclamation-row="reclamationRow"/>
        </template>
      </v-list>
    </v-flex>
    <div style="page-break-after: always"></div>
    <v-flex xs12 class="web-mt-3">
      <v-list>
        <v-list-tile class="auto-height">
          <v-list-tile-content>
            <v-list-tile-title class="mb-2">
              {{ $t('apartment_print.print_attachments') }}, {{ apartment.name }}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <template v-for="(attachment, index) in attachments">
          <print-attachments :key="attachment.fileid + index" :attachment="attachment"/>
        </template>
      </v-list>
    </v-flex>
  </v-layout>
</template>
<script>
import PrintAttachments from "../HandOverPrintView/PrintAttachements.vue"
import PrintReclamationListItem from "../HandOverPrintView/PrintReclamationListItem.vue"
import frends from "@/frendsApi";

export default {
  name: 'apartment-hand-over-details',
  components: {PrintAttachments, PrintReclamationListItem},
  props: {
    apartment: {},
    reclamationRows: {},
    statusDescription: {},
  },
  data(){
    return {
      loading: true,
      attachments: [],
    }
  },
  methods: {
    async reloadHandOverAttachments() {
      try {
        const images = [];
        const liitteet = await frends.getLuovutusliitteet(this.apartment.vertexProject, this.apartment.vertexTargetNumber);
        this.attachments = liitteet;
      } catch (error) {
        this.$store.dispatch('showNotification',
          {
            color: 'error',
            message: this.$t('room.failed_to_fetch_handover_attachments'),
            timeOut: 10000,
            showButton: true,
            buttonText: this.$t('try_again'),
            callback: () => {
              this.reloadHandOverAttachments();
            }
          });
      }
    },
  },
  computed: {
    statusUserInfo() {
      return [this.$formatDate(this.apartment?.statusTimestamp), this.apartment?.statusUser, this.apartment?.statusEmail].filter(x => !!x).join(', ');
    },
  },
  async mounted() {
    await this.reloadHandOverAttachments();
    this.loading = false;
  }
}
</script>
<style>

.auto-height * {
  height: auto !important;
}

.web-mt-3 {
  margin-top: 12px;
}

.web-mb-3 {
  margin-bottom: 12px;
}

</style>
<style scoped>

@media print {
  .web-mt-3 {
    margin-top: 0px;
  }

  .web-mb-3 {
    margin-bottom: 0px;
  }

  .web-pr-2 {
    padding-right: 0px !important;
  }

  .web-pl-2 {
    padding-left: 0px !important;
  }

  body * {
    visibility: hidden;
  }

  #section-to-print, #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    margin: 30px;
    position: absolute;
    left: 0;
    top: 0;
  }

  @page {
    margin: 70px 0 0 0;
  }

  body {
    margin: 1.6cm;
  }
}

</style>
