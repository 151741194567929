<template xmlns:v-touch="http://www.w3.org/1999/xhtml">
  <v-container fluid fill-height ma-0 pa-0 class="room">

    <v-toolbar  color="primary" dark fixed app :extended="($isAdmin || $isTeamMember || $isTeamLeader || $isPuustelliUser) && !loading">
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>
      <hb-back-button fallback="deliverylot" :fallback-params="{deliveryLotId: deliveryLotId, projectId: projectId}" />

      <v-toolbar-title style="margin-left: 0;">{{ deliveryLot ? `${deliveryLot.name} - ${$t('room.handover_printing_info')}` : `${$t('room.loading_handover_data')}...` }}</v-toolbar-title>

      <template slot="extension">
        <v-layout v-if="!loading" justify-start light>
          <v-btn :disabled="loading"
                 :small="$vuetify.breakpoint.mdAndDown"
                 :light="$vuetify.breakpoint.lgAndUp"
                 outline
                 style="margin-left: 0"
                 :loading="loadingPDF"
                 :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'"
                 @click="print"
          >
            {{ $t('room.print_handover_info') }}
          </v-btn>
        </v-layout>
      </template>

    </v-toolbar>

    <hb-loading-indicator v-if="loading" fill-height align-middle />

    <v-layout row wrap v-if="!loading">
      <v-flex xs12>
        <v-container fluid class="pa-3">
          <v-layout row wrap id="section-to-print">
            <v-flex xs12 md6 class="d-flex print-pa-0" :class="$vuetify.breakpoint.mdAndUp && 'web-pr-2'">
              <v-list>
                <v-list-tile class="auto-height">
                  <v-list-tile-content>

                    <v-list-tile-title class="title">
                      {{ $t('apartment_print.project_details') }}
                    </v-list-tile-title>
                    <v-list-tile-sub-title v-if="project && project.clientName">
                      {{ $t('apartment_print.condominium') }}: {{ project.clientName }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      {{ $t('apartment_print.address') }}: {{ project.streetAddress }}, {{ project.postalCode }} {{ project.city }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title v-if="project && project.contactPerson">
                      {{ $t('apartment_print.contact_person') }}: {{ project.contactPerson }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title v-if="project && project.customerInfo">
                      {{ $t('apartment_print.customer') }}: {{ project.customerInfo }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      {{ $t('apartment_print.status') }}: {{ statusDesc }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title v-if="project && project.puusnetProjectNumber">
                      {{ $t('apartment_print.puusnet_project_number') }}: {{ project.puusnetProjectNumber }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title v-if="projectDTO && projectDTO.installationGroup.name">
                      {{ $t('apartment_print.install_group') }}: {{ projectDTO.installationGroup.name }}
                    </v-list-tile-sub-title>

                    <v-list-tile-title class="mt-3 title">
                      {{ $t('apartment_print.delivery_lot_details') }}
                    </v-list-tile-title>
                    <v-list-tile-sub-title v-if="deliveryLot && deliveryLot.name">
                      {{ $t('apartment_print.delivery_lot_name') }}: {{ deliveryLot.name }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title v-if="deliveryLot && deliveryLot.statusCode">
                      {{ $t('apartment_print.delivery_lot_status') }}: {{ statusDescription }}
                    </v-list-tile-sub-title>

                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-flex>
            <v-flex xs12 md6 class="d-flex print-pa0" :class="$vuetify.breakpoint.mdAndUp && 'web-pl-2'">
              <v-list>
                <v-list-tile class="auto-height">
                  <v-list-tile-content>
                    <v-list-tile-title class="title">
                      {{ $t('apartment_print.contacts') }}
                    </v-list-tile-title>
                    <template v-for="(puustelliContactPerson, index) in puustelliContactPersons" >
                      <div :key="puustelliContactPerson.id" :class="index !== 0 && 'mt-1'">
                        <v-list-tile-sub-title>{{ puustelliContactPerson.name != null ? puustelliContactPerson.name : '' }}</v-list-tile-sub-title>
                        <v-list-tile-sub-title>
                          <v-layout row wrap>
                            <v-flex xs12>
                              {{ getPuustelliTitleText(puustelliContactPerson) }}
                            </v-flex>
                            <v-flex>
                              {{ puustelliContactPerson.email != null ? puustelliContactPerson.email : '' }}{{ puustelliContactPerson.phone != null ? ', puh. ' + puustelliContactPerson.phone : '' }}
                            </v-flex>
                          </v-layout>
                        </v-list-tile-sub-title>
                      </div>
                    </template>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-flex>

            <div style="page-break-after: always"></div>

            <template v-if="apartments && apartments.length > 0">
              <v-flex xs12>
                <template v-for="(apartment, index) in apartments">
                  <apartment-hand-over-details :apartment="apartment"
                                               :reclamation-rows="sortedReclamationsByApartmentNumber[apartment.vertexTargetNumber]"
                                               :key="apartment.vertexTargetNumber + index"
                                               :status-description="statusDescription"/>
                  <div :key="index" style="page-break-after: always"></div>
                </template>
              </v-flex>
            </template>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import frends from "../frendsApi";
import _ from 'lodash';
import ApartmentHandOverDetails from "@/components/HandOverDeliveryLotPrintView/ApartmentHandOverDetails.vue";
import { jsPDF } from 'jspdf';
import MimeTypes from "mime-types";
import api from "@/api";


export default {
  name: "HandOverDeliveryLotPrintView",
  components: {ApartmentHandOverDetails},
  props: {
    projectId: {
      type: String,
      default: '',
    },
    deliveryLotId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      reclamationRows: null,
      loadingPDF: false,
    }
  },
  methods: {
    async reloadReclamationRows() {
      try {
        let rivit = await frends.getReklamaatioRivit(this.projectId, this.deliveryLotId, null, null, this.$kielinro);
        //rivit = rivit.filter(rivi => parseInt(rivi.huonenro, 10) === parseInt(this.vertexTargetNumber, 10) && this.vertexProjectNumber === rivi.projektinro);
        this.reclamationRows = rivit;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('showNotification',
          {
            color: 'error',
            message: this.$t('reclamation.failed_to_fetch_reclamation_rows'),
            timeOut: 10000,
            showButton: true,
            buttonText: this.$t('try_again'),
            callback: () => {
              this.reloadReclamationRows();
            }
          });
      }
    },
    getPuustelliTitleText(contactPerson) {
      return contactPerson.title.join(', ');
    },
    async print() {
      this.loadingPDF = true;

      const pdf = new jsPDF();
      const headerFontSize = 20;
      const subHeaderFontSize = 16;
      const bodyFontSize = 14;
      const lineHeight = 0.5;
      let y = 20;

      const lineBreak = () => {
        y = y + (lineHeight * bodyFontSize);
      }

      pdf.setFontSize(headerFontSize);
      pdf.text(20, y, this.$t('apartment_print.project_details'));
      y = y + 3;
      pdf.setFontSize(bodyFontSize);
      if (this.project.clientName) {
        lineBreak();
        pdf.text(20, y, `${this.$t('apartment_print.condominium')}: ${this.project.clientName}`);
      }
      if (this.project.city) {
        lineBreak();
        pdf.text(20, y, `${this.$t('apartment_print.address')}: ${this.project.streetAddress}, ${this.project.postalCode}, ${this.project.city}`);
      }
      if (this.project.contactPerson) {
        lineBreak();
        pdf.text(20, y, `${this.$t('apartment_print.contact_person')}: ${this.project.contactPerson}`);
      }
      if (this.project.customerInfo) {
        lineBreak();
        pdf.text(20, y, `${this.$t('apartment_print.customer')}: ${this.project.customerInfo}`);
      }
      if (this.statusDesc) {
        lineBreak();
        pdf.text(20, y, `${this.$t('apartment_print.status')}: ${this.statusDesc}`);
      }
      if (this.project.puusnetProjectNumber) {
        lineBreak();
        pdf.text(20, y, `${this.$t('apartment_print.puusnet_project_number')}: ${this.project.puusnetProjectNumber}`);
      }
      if (this.projectDTO.installationGroup.name) {
      lineBreak();
      pdf.text(20, y, `${this.$t('apartment_print.install_group')}: ${this.projectDTO.installationGroup.name}`);
      }

      y = y + (lineHeight * bodyFontSize) + (lineHeight * bodyFontSize);
      pdf.setFontSize(headerFontSize);
      pdf.text(20, y, this.$t('apartment_print.delivery_lot_details'));
      y = y + 3;
      pdf.setFontSize(bodyFontSize);
      if (this.deliveryLot.name) {
        lineBreak();
        pdf.text(20, y, `${this.$t('apartment_print.delivery_lot_name')}: ${this.deliveryLot.name}`);
      }
      if (this.statusDescription) {
        lineBreak();
        pdf.text(20, y, `${this.$t('apartment_print.delivery_lot_status')}: ${this.statusDescription}`);
      }

      y = y + (lineHeight * bodyFontSize) + (lineHeight * bodyFontSize);
      pdf.setFontSize(headerFontSize);
      pdf.text(20, y, this.$t('apartment_print.contacts'));
      y = y + 3;
      pdf.setFontSize(bodyFontSize);
      this.puustelliContactPersons.forEach(puustelliContactPerson => {
        if (puustelliContactPerson.name) {
          y = y + (lineHeight * bodyFontSize);
          pdf.text(20, y, `${puustelliContactPerson.name}`);
        }
        lineBreak();
        pdf.setFontSize(bodyFontSize * 0.9);
        pdf.setTextColor(80);
        pdf.text(20, y, this.getPuustelliTitleText(puustelliContactPerson));
        lineBreak();
        const contact = [puustelliContactPerson.email, `puh. ${puustelliContactPerson.phone}`].filter(x => !!x);
        pdf.text(20, y, contact.join(", "));
        y = y + 2;
        pdf.setFontSize(bodyFontSize);
        pdf.setTextColor(0);
      });

      for (const apartment of this.apartments) {
        pdf.addPage();
        y = 20;
        pdf.setTextColor(0);
        pdf.setFontSize(headerFontSize);
        pdf.text(20, y, `${apartment.name} (${this.statusDescription})`);
        y = y + 3;

        pdf.setTextColor(0);
        pdf.setFontSize(subHeaderFontSize);
        lineBreak();
        pdf.text(20, y, `${this.$t('apartment_print.comments')}`);
        if (apartment.handOverComments) {
          lineBreak();
          pdf.setFontSize(bodyFontSize * 0.9);
          pdf.setTextColor(80);
          pdf.text(20, y, apartment.handOverComments);
          if (this.statusUserInfo(apartment)) {
            lineBreak();
            pdf.text(20, y, this.statusUserInfo(apartment));
          }
        }

        lineBreak();
        y = y + 3;
        pdf.setFontSize(subHeaderFontSize);
        pdf.setTextColor(0);
        pdf.text(20, y, this.$t('apartment_print.reclamation_rows'));
        y = y + 1;
        if (this.sortedReclamationsByApartmentNumber[apartment.vertexTargetNumber]) {
          this.sortedReclamationsByApartmentNumber[apartment.vertexTargetNumber].forEach(reclamationRow => {
            lineBreak();
            if (y > 260) {
              pdf.addPage();
              y = 20;
            }
            pdf.setFontSize(bodyFontSize * 0.9);
            pdf.setTextColor(80);
            pdf.text(20, y, reclamationRow.malliselite ? reclamationRow.rekl_tuoteselite : this.$t('reclamation.no_product'));
            pdf.setFontSize(bodyFontSize * 0.75);
            pdf.setTextColor(60);
            y = y + 6;
            pdf.text(20, y, `${this.$t('reclamation.order_number')}: ${reclamationRow.rekl_tilausnro ? reclamationRow.rekl_tilausnro : this.$t('reclamation.no_order_number')}`);
            pdf.text(100, y, `${this.$t('reclamation.type')}: ${reclamationRow.rekl_tyyppiselite}`);
            y = y + 6;
            pdf.text(20, y, `${this.$t('reclamation.delivery')}: ${reclamationRow.rekl_jakelupvm ? this.$formatDateIncludeWeekday(reclamationRow.rekl_jakelupvm) : this.$t('reclamation.no_delivery_date')}`);
            pdf.text(100, y, `${this.$t('reclamation.status')}: ${reclamationRow.rekl_statusteksti ? reclamationRow.rekl_statusteksti : ''}`);
            y = y + 6;
            pdf.text(20, y, `${this.$t('reclamation.rekltoimeranro')}: ${reclamationRow.rekltoimeranro ? reclamationRow.rekltoimeranro : ''}`);
            pdf.text(100, y, `${this.$t('reclamation.created')}: ${this.$formatDateNoTime(reclamationRow.rekl_rivinperustamispvm)}`);
            y = y + 6;
            pdf.text(20, y, `${this.$t('reclamation.rekltoimeranimi')}: ${reclamationRow.rekltoimeranimi ? reclamationRow.rekltoimeranimi : ''}`);
            y = y + 3;
          });
        }

        let attachments;
        try {
          attachments = await frends.getLuovutusliitteet(apartment.vertexProject, apartment.vertexTargetNumber);
        } catch (error) {
          console.log(error);
        }

        if (attachments && attachments.length > 0) {
          const imagesPerPage = 4; // 2x2 grid
          const cellWidth = pdf.internal.pageSize.getWidth() / 2 - 20; // 20px margin on each side
          const cellHeight = pdf.internal.pageSize.getHeight() / 2 - 20;

          let imageIndex = 0;

          pdf.addPage();
          pdf.setFontSize(subHeaderFontSize);
          pdf.setTextColor(0);
          pdf.text(20, 20, `${this.$t('apartment_print.print_attachments')}, ${apartment.name}`);

          for (const attachment of attachments) {
            if (this.isImageFile(attachment) && attachment.public_url) {
              const img = await api.getAttachmentImageAsDataUrl(attachment.public_url);

              // Create a hidden image element to get the original dimensions
              const tempImg = new Image();
              tempImg.src = img;
              await new Promise((resolve) => {
                tempImg.onload = resolve; // Wait for image to load
              });

              const originalWidth = tempImg.width;
              const originalHeight = tempImg.height;

              // Calculate scaled dimensions while maintaining aspect ratio
              let scaledWidth = cellWidth;
              let scaledHeight = (originalHeight / originalWidth) * cellWidth;

              if (scaledHeight > cellHeight) {
                scaledHeight = cellHeight;
                scaledWidth = (originalWidth / originalHeight) * cellHeight;
              }

              // Determine grid position (row and column)
              const row = Math.floor(imageIndex / 2) % 2;
              const col = imageIndex % 2;

              const x = 10 + col * (cellWidth + 10) + (cellWidth - scaledWidth) / 2; // Center within cell
              const y = 30 + row * (cellHeight + 10) + (cellHeight - scaledHeight) / 2; // Center within cell

              pdf.addImage(img, attachment.type, x, y, scaledWidth, scaledHeight);

              imageIndex++;

              // Add a new page after 4 images
              if (imageIndex % imagesPerPage === 0 && imageIndex < attachments.length) {
                pdf.addPage();
              }
            }
          }
        }
      }

      this.loadingPDF = false;
      pdf.save(`${this.deliveryLot.name} - ${this.$t('apartment_print.handover_details')}.pdf`);
    },
    isImageFile(file) {
      return this.$isImageFile(file.tiedostonimi, file.type);
    },
    async reloadProject() {
      await this.$store.dispatch('reloadProjectDTO', this.projectId);
    },
    statusUserInfo(apartment) {
      return [this.$formatDate(apartment?.statusTimestamp), apartment?.statusUser, apartment?.statusEmail].filter(x => !!x).join(', ');
    },
  },
  computed: {
    statusDescription() {
      const statusCode = this.deliveryLot.statusCode;
      const statuses = this.systemData.deliveryLotStatuses;
      const status = statuses.find(s => s.key === statusCode);
      return status.value;
    },
    puustelliContactPersons() {
      const puustelliContactPersons = [];
      const owner = Object.assign({}, this.projectDTO.owner);
      if (owner) {
        owner.title = [this.$t('projects.titles.owner')];
        puustelliContactPersons.push(owner);
      }
      const clientSecretary = Object.assign({}, this.projectDTO.clientSecretary);
      if (clientSecretary) {
        const contactIndex = puustelliContactPersons.findIndex(c => c.id === clientSecretary.id);
        if (contactIndex > -1) {
          puustelliContactPersons[contactIndex].title.push(this.$t('projects.titles.clientSecretary'));
        } else {
          clientSecretary.title = [this.$t('projects.titles.clientSecretary')];
          puustelliContactPersons.push(clientSecretary);
        }
      }
      const secretary = Object.assign({}, this.projectDTO.secretary);
      if (secretary) {
        const contactIndex = puustelliContactPersons.findIndex(c => c.id === secretary.id);
        if (contactIndex > -1) {
          puustelliContactPersons[contactIndex].title.push(this.$t('projects.titles.secretary'));
        } else {
          secretary.title = [this.$t('projects.titles.secretary')];
          puustelliContactPersons.push(secretary);
        }
      }
      const manager = Object.assign({}, this.projectDTO.manager);
      if (manager) {
        const contactIndex = puustelliContactPersons.findIndex(c => c.id === manager.id);
        if (contactIndex > -1) {
          puustelliContactPersons[contactIndex].title.push(this.$t('projects.titles.manager'));
        } else {
          manager.title = [this.$t('projects.titles.manager')];
          puustelliContactPersons.push(manager);
        }
      }
      return puustelliContactPersons;
    },
    statusDesc() {
      const statusCode = this.project.projectStatus;
      const statuses = this.systemData.projectStatuses;
      const status = statuses.find(t => t.key === statusCode);
      return status.value;
    },
    systemData() {
      return this.$store.state.systemData;
    },
    loading() {
      return !(this.deliveryLot && this.reclamationRows);
    },
    apartments() {
      if (this.deliveryLot) {
        const deliveryLot = {...this.deliveryLot};
        return deliveryLot.apartments?.sort((a, b) => a.order - b.order);
      }
      return null;
    },
    sortedReclamationsByApartmentNumber() {
      if (!this.reclamationRows) return [];

      return _.groupBy(this.reclamationRows, 'huonenro');
    },
    deliveryLot() {
      if (this.projectDTO && this.projectDTO.deliveryLots) {
        return this.projectDTO.deliveryLots.find(lot => lot.id === this.deliveryLotId);
      }
      return null;
    },
    projectDTO() {
      return this.$store.state.projectDTO;
    },
    project() {
      return this.$store.state.projectDTO.project;
    },
  },
  async mounted() {
    await this.reloadProject();
    await this.reloadReclamationRows();
  }
}
</script>

<style>

.auto-height * {
  height: auto !important;
}
.web-pr-2 {
  padding-right: 8px;
}
.web-pl-2 {
  padding-left: 8px;
}

</style>

<style scoped>

@media print {
  .web-mt-3 {
    margin-top: 0px;
  }
  .web-mb-3 {
    margin-bottom: 0px;
  }
  .web-pr-2 {
    padding-right: 0px !important;
  }
  .web-pl-2 {
    padding-left: 0px !important;
  }
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    margin: 30px;
    position: absolute;
    left: 0;
    top: 0;
  }

  @page { margin: 70px 0 0 0; }
  body  { margin: 1.6cm; }
}

</style>
