<template>
  <v-container fluid fill-height ma-0 pa-0 class="tyomaarays">

    <v-toolbar color="primary" dark fixed app>
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>
      <hb-back-button fallback="tyomaaraykset" :fallback-params="{ projectId: projectId }" />

      <v-toolbar-title style="margin-left: 0;">{{ $t('tyomaarays.title') }}: {{ tyomaarays.nimi }}</v-toolbar-title>

      <template v-if="$isPuustelliUser || $isAdmin || $isTeamLeader">
        <v-spacer></v-spacer>

        <v-menu bottom left @click.native.stop>
          <v-btn small icon slot="activator"><v-icon>more_vert</v-icon></v-btn>
          <v-list class="pt-0 pb-0">
            <v-list-tile @click="editTyomaarays" target="_blank">
              <v-list-tile-action><v-icon>edit</v-icon></v-list-tile-action>
              <v-list-tile-title>{{ $t('tyomaaraykset.edit') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="deleteTyomaarays" target="_blank" v-if="$isPuustelliUser || $isAdmin">
              <v-list-tile-action><v-icon>delete</v-icon></v-list-tile-action>
              <v-list-tile-title>{{ $t('tyomaaraykset.delete') }}</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </template>

      <template slot="extension">
        <v-layout v-if="!loading" justify-start light>
          <v-btn :loading="updatingStatus"
                 :small="$vuetify.breakpoint.mdAndDown"
                 :light="$vuetify.breakpoint.lgAndUp"
                 outline
                 style="margin-left: 0"
                 :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'"
                 @click.native="openStatusDialog"
                 v-if="($isAdmin || $isTeamMember || $isTeamLeader || $isPuustelliUser)">
            {{ $t('tyomaaraykset.change_status') }}
          </v-btn>
        </v-layout>
      </template>

    </v-toolbar>

    <hb-loading-indicator v-if="loading" fill-height align-middle />

    <v-fade-transition>
      <v-layout v-if="!loading" row wrap>
        <v-flex xs12 class="tyomaarays-holder">

          <v-container fluid :grid-list-lg="$vuetify.breakpoint.lgAndUp" :class="{'pa-0': $vuetify.breakpoint.mdAndDown}">
            <v-layout row wrap>

              <v-flex xs12 lg5 xl4 :pb-0="$vuetify.breakpoint.mdAndDown">
                <v-card v-if="tyomaarays" :dark="$vuetify.breakpoint.mdAndDown" :class="{'elevation-0': $vuetify.breakpoint.mdAndDown}">
                  <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text">
                    {{ $t('tyomaarays.tyomaarays_info') }}
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-subheader v-if="$vuetify.breakpoint.mdAndDown">{{ $t('tyomaarays.tyomaarays_info') }}</v-subheader>
                    <v-list two-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0">

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-hashtag</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ $t('tyomaaraykset.id') }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ tyomaarays.tyomaaraysid }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>

                      <v-divider inset></v-divider>

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-cube</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ $t('tyomaaraykset.name') }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ tyomaarays.nimi }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>

                      <v-divider inset></v-divider>

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">update</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ $t('tyomaaraykset.status') }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ tyomaaraysStatus }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>

                      <v-divider inset></v-divider>

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">info</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ $t('tyomaaraykset.extra_info') }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ tyomaarays.lisatieto }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>

                      <v-divider inset></v-divider>

                      <v-list-tile class="full-height py-2" style="height: auto !important;">
                        <v-list-tile-avatar>
                          <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">list</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ $t('tyomaaraykset.description') }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly, 'wrap-text': true}">{{ tyomaarays.kuvaus }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>
                      <v-divider inset></v-divider>

                        <v-list two-line class="pb-0 pt-0">
                          <v-list-tile :to="{name: 'project', params: { id: projectId }}">
                            <v-list-tile-avatar>
                              <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">assignment</v-icon>
                            </v-list-tile-avatar>
                            <v-list-tile-content>
                              <v-list-tile-title>{{ tyomaarays.asiakasnimi }}</v-list-tile-title>
                              <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ tyomaarays.d365projektinimi }}</v-list-tile-sub-title>
                            </v-list-tile-content>
                            <v-list-tile-action>
                              <v-icon>chevron_right</v-icon>
                            </v-list-tile-action>
                          </v-list-tile>
                          <v-divider inset></v-divider>
                        </v-list>

                      <v-list two-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0 pt-0">
                        <v-list-tile v-if="deliveryLotId" :to="{name: 'deliverylot', params: { projectId: projectId, deliveryLotId: deliveryLotId }}">
                          <v-list-tile-avatar>
                            <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-truck-loading</v-icon>
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>{{ $tc('delivery_lot.title', 1) }}</v-list-tile-title>
                            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ tyomaarays.d365toimituseranimi }}</v-list-tile-sub-title>
                          </v-list-tile-content>
                          <v-list-tile-action>
                            <v-icon>chevron_right</v-icon>
                          </v-list-tile-action>
                        </v-list-tile>
                        <v-divider inset></v-divider>
                      </v-list>

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-screwdriver</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ $t('tyomaaraykset.installation_group') }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ tyomaarays.asennusryhmanimi }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>

                      <v-divider inset></v-divider>

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">event</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ $t('tyomaaraykset.tyopvm') }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ deliveryDate }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>

                      <v-divider inset></v-divider>

                      <template v-if="puustelliResponsible">
                        <v-list-tile>
                          <v-list-tile-avatar>
                            <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">person</v-icon>
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>{{ $t('tyomaaraykset.puustelli_responsible') }}</v-list-tile-title>
                            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ puustelliResponsible }}</v-list-tile-sub-title>
                          </v-list-tile-content>
                          <v-list-tile-action>
                            <v-menu bottom left>
                              <v-btn slot="activator" icon><v-icon>more_vert</v-icon></v-btn>
                              <v-list>
                                <v-list-tile v-if="tyomaarays.puustellivastaavaemail" :href="`mailto:${tyomaarays.puustellivastaavaemail}`">
                                  <v-list-tile-action><v-icon>email</v-icon></v-list-tile-action>
                                  <v-list-tile-title>{{ $t('projects.send_email') }}</v-list-tile-title>
                                </v-list-tile>
                              </v-list>
                            </v-menu>
                          </v-list-tile-action>
                        </v-list-tile>

                        <v-divider inset></v-divider>
                      </template>
                      <template v-if="customerContactPerson">
                        <v-list-tile>
                          <v-list-tile-avatar>
                            <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">person</v-icon>
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>{{ $t('tyomaaraykset.customer_contact_person') }}</v-list-tile-title>
                            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ customerContactPerson }}</v-list-tile-sub-title>
                          </v-list-tile-content>
                          <v-list-tile-action v-if="tyomaarays.asiakasyhteyshenkiloemail">
                            <v-menu bottom left>
                              <v-btn slot="activator" icon><v-icon>more_vert</v-icon></v-btn>
                              <v-list>
                                <v-list-tile v-if="tyomaarays.asiakasyhteyshenkiloemail" :href="`mailto:${tyomaarays.asiakasyhteyshenkiloemail}`">
                                  <v-list-tile-action><v-icon>email</v-icon></v-list-tile-action>
                                  <v-list-tile-title>{{ $t('projects.send_email') }}</v-list-tile-title>
                                </v-list-tile>
                              </v-list>
                            </v-menu>
                          </v-list-tile-action>
                        </v-list-tile>

                        <v-divider inset></v-divider>
                      </template>

                      <template v-if="tyomaarays.laskutusasiakas">
                        <v-list-tile>
                          <v-list-tile-avatar>
                            <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">check</v-icon>
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>{{ $t('tyomaarays.billing_customer') }}</v-list-tile-title>
                            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ tyomaarays.laskutusasiakas }}</v-list-tile-sub-title>
                          </v-list-tile-content>
                        </v-list-tile>

                        <v-divider inset></v-divider>
                      </template>

                      <template v-if="billingInfo">
                        <v-list-tile>
                          <v-list-tile-avatar>
                            <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">check</v-icon>
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>{{ $t('tyomaarays.billing_address') }}</v-list-tile-title>
                            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ billingInfo }}</v-list-tile-sub-title>
                          </v-list-tile-content>
                        </v-list-tile>

                        <v-divider inset></v-divider>
                      </template>

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">person</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ $t('tyomaarays.creator') }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">
                            {{ tyomaarays.tekija ? tyomaarays.tekija : '' }}
                          </v-list-tile-sub-title>
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-menu bottom left>
                            <v-btn slot="activator" icon><v-icon>more_vert</v-icon></v-btn>
                            <v-list>
                              <v-list-tile v-if="tyomaarays.tekijaemail != null" :href="`mailto:${tyomaarays.tekijaemail}`">
                                <v-list-tile-action><v-icon>email</v-icon></v-list-tile-action>
                                <v-list-tile-title>{{ $t('projects.send_email') }}</v-list-tile-title>
                              </v-list-tile>
                            </v-list>
                          </v-menu>
                        </v-list-tile-action>
                      </v-list-tile>
                      <v-divider inset></v-divider>

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">access_time</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ $t('tyomaarays.created') }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ $formatDate(tyomaarays.perustettupvm) }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>

                    </v-list>

                  </v-card-text>
                </v-card>

              </v-flex>

              <v-flex xs12 lg7 xl8 :pt-0="$vuetify.breakpoint.mdAndDown">

                <v-card :class="{'elevation-0': $vuetify.breakpoint.mdAndDown}">
                  <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text">
                    {{ $t('tyomaarays.rows') }}
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-progress-linear v-if="loadingRivit" height="2" indeterminate class="pt-0 mb-0 pb-0" style="margin-top: -2px;" />
                    <v-subheader v-if="$vuetify.breakpoint.mdAndDown">{{ $t('tyomaarays.rows') }}</v-subheader>
                    <template v-for="rivi in tyomaaraysRivit">
                      <tyomaarays-rivi-list-item :key="rivi.id" :row="rivi" @row-clicked="riviClicked" @delete-clicked="deleteTyomaaraysRivi" />
                    </template>
                  </v-card-text>
                  <v-card-actions class="d-block">
                    <template v-if="$isTeamMember || $isTeamLeader || $isPuustelliUser || $isAdmin">
                      <v-btn block
                             @click="markReclamationsAsInstalled"
                             :disabled="updatingStatuses || !(selectedReclamationStatus === 4 || selectedReclamationStatus === 6)"
                             :light="$vuetify.breakpoint.lgAndUp"
                             outline
                             class="my-1"
                             color="primary">{{ $t('reclamation.mark_as_installed') }}</v-btn>
                    </template>
                    <v-btn @click="createTyomaaraysRivi" block outline class="my-1 mx-0">{{ $t('tyomaarays.add_row') }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>

            </v-layout>
          </v-container>

        </v-flex>
      </v-layout>
    </v-fade-transition>

    <confirm-dialog ref="confirmDelete" />

    <edit-tyonaarays-dialog
      ref="editTyomaaraysDialog"
      :projectDTO="projectDTO"
      @tyomaarays-saved="handleTyomaarainUpdate"
    />

    <edit-tyomaarays-rivi-dialog
      ref="editTyomaaraysRiviDialog"
      :projectDTO="projectDTO"
      :tyomaarays="tyomaarays"
      :tyomaarays-rivit="tyomaaraysRivit"
      @saved="riviSaved"
    />

    <tyomaarain-status-dialog ref="statusDialog"
                              :tyomaarain-statuses="tyomaaraysStatukset"
       @status-changed="handleTyomaarainUpdate" />

    <confirm-dialog ref="confirmReclamationStatusChanges" />

  </v-container>
</template>

<script>
import frendsApi from '../frendsApi.js';
import ConfirmDialog from "../components/ConfirmDialog.vue";
import EditTyonaaraysDialog from "../components/SingleTyomaaraysView/EditTyomaaraysDialog.vue";
import EditTyomaaraysRiviDialog from "../components/SingleTyomaaraysView/EditTyomaaraysRiviDialog.vue";
import TyomaaraysRiviListItem from "@/components/SingleTyomaaraysView/TyomaaraysRiviListItem";
import TyomaarainStatusDialog from "@/components/SingleTyomaaraysView/TyomaarainStatusDialog";
import api from "@/api";

export default {
  name: "SingleTyomaaraysView",
  components: {
    TyomaarainStatusDialog,
    TyomaaraysRiviListItem,
    EditTyonaaraysDialog,
    EditTyomaaraysRiviDialog,
    ConfirmDialog: ConfirmDialog,
  },
  props: {
    tyomaaraysid: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      loading: true,
      subtleLoading: false,
      loadingRivit: false,
      tyomaarays: {},
      tyomaaraysRivit: [],
      updatingStatus: false,
      updatingStatuses: false,
      projectDTO: null,
    }
  },
  methods: {
    async updateReclamationRowStatus(reclamationRow, status) {
      this.$set(reclamationRow, 'updatingStatus', true);
      if (reclamationRow.rekl_rivitunniste) {
        const rowIndex = this.tyomaaraysRivit.findIndex(tr => tr.rekl_rivitunniste === reclamationRow.rekl_rivitunniste);
        try {
          const updatedRow = await frendsApi.updateReklamaatioRiviStatus(reclamationRow.rekl_rivitunniste, status, this.$kielinro, this.$store.state.auth.user);
          this.tyomaaraysRivit[rowIndex].selected = false;
          this.tyomaaraysRivit[rowIndex].updatingStatus = false;
          this.tyomaaraysRivit[rowIndex].rekl_statusnro = updatedRow.rekl_statusnro;
          this.tyomaaraysRivit[rowIndex].rekl_statusteksti = updatedRow.rekl_statusteksti;
          this.tyomaaraysRivit[rowIndex].rekl_statusvari = updatedRow.rekl_statusvari;
          /*
        if (rowIndex > -1) {
          this.reclamationRows.splice(rowIndex, 1, updatedRow);
        }
        */
          return updatedRow;
        } catch (error) {
          if (rowIndex > -1) {
            this.reclamationRows[rowIndex].updatingStatus = false;
          }
          throw error;
        }
      }
    },
    async markReclamationsAsInstalled() {
      const reclamationsToUpdate = [];
      this.tyomaaraysRivit.forEach(tr => {
        if (tr.selected) reclamationsToUpdate.push(tr);
      });

      const confirm = await this.$refs.confirmReclamationStatusChanges.open(this.$t('reclamation.mark_as_installed'), this.$t('reclamation.mark_as_installed_amount', {amount: reclamationsToUpdate.length}), { color: 'warning' });
      if (!confirm) return;

      this.updatingStatuses = true;

      const promises = [];
      reclamationsToUpdate.forEach(row => {
        promises.push(this.updateReclamationRowStatus(row, 'ASENNETTU'));
      });

      try {
        await Promise.all(promises);
        this.updatingStatuses = false;
      } catch (error) {
        console.log(error);
        this.updatingStatuses = false;
        this.$store.dispatch('showNotification',
          {
            color: 'error',
            message: this.$t('reclamation.failed_to_update_reclamation_row_statuses'),
            timeOut: 4000,
            showButton: false,
          });
      }
    },
    openStatusDialog() {
      this.$refs.statusDialog.openDialog(this.tyomaarays);
    },
    handleTyomaarainUpdate(tyomaarain) {
      this.tyomaarays = tyomaarain;
      if (this.tyomaarays.d365projektiid) {
        this.tyomaarays.d365projektiid = this.$removeCurlyBrackets(this.tyomaarays.d365projektiid);
      }
      if (this.tyomaarays.d365toimituseraid) {
        this.tyomaarays.d365toimituseraid = this.$removeCurlyBrackets(this.tyomaarays.d365toimituseraid);
      }
    },
    async riviClicked(rivi) {
      if (rivi.rekl_rivitunniste) {
        this.$router.push({name: 'reclamationrow', params: { rowId: rivi.rekl_rivitunniste}});
      }
    },
    async riviSaved() {
      this.reloadTyomaaraysRivit();
    },
    async createTyomaaraysRivi() {
      this.$refs.editTyomaaraysRiviDialog.open({});
    },
    async deleteTyomaaraysRivi(rivi) {
      const confirm = await this.$refs.confirmDelete.open(this.$t('tyomaarays.delete_row'), this.$t('tyomaarays.delete_row_confirm'), { color: 'warning' });
      if (!confirm)
        return;

      try {
        await frendsApi.deleteTyomaaraysRivi(this.tyomaaraysid, rivi.tyomaaraysrivinro, this.$kielinro);
        await this.reloadTyomaaraysRivit();
      } catch (error) {
        console.log(error);
        this.$store.dispatch('showNotification', {
          color: 'error',
          message: this.$t('tyomaarays.failed_to_delete_row'),
          timeOut: 4000,
          showButton: false,
        });
      }
    },
    async editTyomaarays() {
      await this.$refs.editTyomaaraysDialog.open(this.tyomaarays);
    },
    async deleteTyomaarays() {
      const confirm = await this.$refs.confirmDelete.open(this.$t('tyomaaraykset.delete'), this.$t('tyomaaraykset.delete_confirm'), { color: 'warning' });
      if (!confirm)
        return;

      try {
        await frendsApi.deleteTyomaarays(this.tyomaaraysid, this.$kielinro);
        this.$router.push({
          name: 'tyomaaraykset',
          params: {
            projectId: this.tyomaarays.d365projektiid,
          },
        })
      } catch (error) {
        console.log(error);
        this.$store.dispatch('showNotification', {
          color: 'error',
          message: this.$t('tyomaaraykset.failed_to_delete'),
          timeOut: 4000,
          showButton: false,
        });
      }
    },
    async reloadProject(id) {
      console.log('reloadProject', id)
      const storedProject = this.$store.state.projectDTO;
      if (!storedProject || !storedProject.project || storedProject.project.id !== id) {
        this.loading = true;
        await this.$store.dispatch('reloadProjectDTO', id);
        this.loading = false;
      } else {
        this.subtleLoading = true;
        this.loading = false;
        await this.$store.dispatch('reloadProjectDTO', id);
        this.subtleLoading = false;
      }

      this.projectDTO = this.$store.state.projectDTO;
    },
    async reloadTyomaarays() {
      this.loading = true;

      try {
        const tyomaarays = await frendsApi.getTyomaarays(this.tyomaaraysid, this.$kielinro);
        this.tyomaarays = tyomaarays;
        if (this.tyomaarays.d365projektiid) {
          this.tyomaarays.d365projektiid = this.$removeCurlyBrackets(this.tyomaarays.d365projektiid);
        }
        if (this.tyomaarays.d365toimituseraid) {
          this.tyomaarays.d365toimituseraid = this.$removeCurlyBrackets(this.tyomaarays.d365toimituseraid);
        }
        await this.reloadTyomaaraysRivit();

        if (this.$isAdmin || this.$isPuustelliUser) {
          await this.reloadProject(this.tyomaarays.d365projektiid);
        }
      } catch (error) {
        console.log(error);
        this.$store.dispatch('showNotification',
          {
            color: 'error',
            message: this.$t('tyomaaraykset.failed_to_fetch_one'),
            timeOut: 4000,
            showButton: false,
          });
      }
      this.loading = false;
    },
    async reloadTyomaaraysRivit() {
      this.loadingRivit = true;
      try {
        const tyomaaraysRivit = await frendsApi.getTyomaaraysRivit(this.tyomaaraysid, this.$kielinro);
        tyomaaraysRivit.forEach(tr => tr.selected = false);
        this.tyomaaraysRivit = tyomaaraysRivit;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('showNotification',
          {
            color: 'error',
            message: this.$t('tyomaaraykset.failed_to_fetch_rows'),
            timeOut: 4000,
            showButton: false,
          });
      }
      this.loadingRivit = false;
    },
  },
  computed: {
    selectedReclamationStatus() {
      const rIndex = this.tyomaaraysRivit.findIndex(row => row.selected);
      if (rIndex > -1) {
        return parseInt(this.tyomaaraysRivit[rIndex].rekl_statusnro, 10);
      }
      return false;
    },
    tyomaaraysStatukset() {
      return this.$store.state.tyomaaraysStatukset;
    },
    systemData() {
      return this.$store.state.systemData;
    },
    tyomaaraysStatus() {
      if (this.tyomaaraysStatukset) {
        return this.tyomaaraysStatukset.find(s => s.koodi === this.tyomaarays.tyomaarainstatus).selite;
      }
      return '';
    },
    billingInfo() {

      let laskutusmaanro = '';
      if (this.tyomaarays.laskutusmaanro) {
        const maa = this.$store.state.countries.find(c => c.koodi === this.tyomaarays.laskutusmaanro);
        if (maa) {
          laskutusmaanro = maa.selite;
        }
      }

      const laskutusosoite = this.tyomaarays.laskutusosoite || '';
      const laskutuspostinro = this.tyomaarays.laskutuspostinro || '';
      const laskutuspostitoimp = this.tyomaarays.laskutuspostitoimp || '';

      const parts = [laskutusosoite, laskutuspostinro, laskutuspostitoimp, laskutusmaanro];

      return parts.filter(p => p).join(', ')
    },
    customerContactPerson() {
      let c = this.tyomaarays.asiakasyhteyshenkilo || '';
      if (this.tyomaarays.asiakasyhteyshenkiloemail) {
        c += ` (${this.tyomaarays.asiakasyhteyshenkiloemail})`;
      }
      return c;
    },
    puustelliResponsible() {
      let c = this.tyomaarays.puustellivastaava || '';
      if (this.tyomaarays.puustellivastaavaemail) {
        c += ` (${this.tyomaarays.puustellivastaavaemail})`;
      }
      return c;
    },
    deliveryDate() {
      return this.tyomaarays.tyopvm;
    },
    projectId() {
      return this.$removeCurlyBrackets(this.tyomaarays.d365projektiid);
    },
    deliveryLotId() {
      return this.$removeCurlyBrackets(this.tyomaarays.d365toimituseraid);
    },
  },
  async mounted() {
    if (!this.$store.state.tyomaaraysStatukset) {
      await this.$store.dispatch('reloadTyomaaraysStatukset', {kielinro: this.$kielinro});
    }
    await this.reloadTyomaarays();

    this.$vuetify.goTo(0, {
      duration: 0,
      offset: 0,
    });
  }
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
.tyomaarays {
  .v-toolbar__extension {
    background-color: #b30000 !important;
    border-color: #b30000 !important;
  }

  .topbar-search {
    .v-input__slot {
      padding: 0 !important;
    }
  }

  @media screen and (min-width: 1264px) {
    .v-toolbar__extension {
      background-color: #fff !important;
      border-color: #fff !important;
    }
  }
}

.tyomaarays-row-list {
  .v-list__tile {
    height: auto !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .v-list__tile__title {
    height: auto;
  }

  .v-list__tile__sub-title, .v-list__tile__title {
    white-space: normal;
  }
}
.wrap-text {
  word-wrap: break-word;
  white-space: pre-wrap;
}
.full-height {
  .v-list__tile {
    height: 100% !important;
  }
}
</style>
