<template>
  <div
    v-if="isImageFile"
    class="section-to-print my-3 px-2"
    style="page-break-inside: avoid; max-width: 45%; max-height: 45%; display: inline-block"
  >
    <img :id="attachment.tiedostonimi" style="max-width: 100%; max-height: 100% !important" :src="attachment.public_url" />
  </div>
</template>

<script>
import MimeTypes from "mime-types";

export default {
  props: ['attachment'],
  name: "PrintAttachments",
  computed: {
    isImageFile() {
      return this.$isImageFile(this.attachment.tiedostonimi, this.attachment.type);
    },
  },
}
</script>

<style>
.web-pr-2 {
  padding-right: 8px;
}
.web-pl-2 {
  padding-left: 8px;
}
.web-mt-3 {
  margin-top: 12px;
}
.web-mb-3 {
  margin-bottom: 12px;
}

</style>

<style scoped>

@media print {
  .web-mt-3 {
    margin-top: 0px;
  }
  .web-mb-3 {
    margin-bottom: 0px;
  }
  .web-pr-2 {
    padding-right: 0px !important;
  }
  .web-pl-2 {
    padding-left: 0px !important;
  }
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    margin: 30px;
    position: absolute;
    left: 0;
    top: 0;
  }

  @page { margin: 0 0 0 0; }
  body  { margin: 1.6cm; }
}

</style>
